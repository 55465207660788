$px_0: 0;
$px_2: 2px;
$px_4: 4px;
$px_6: 6px;
$px_8: 8px;
$px_10: 10px;
$px_12: 12px;
$px_14: 14px;
$px_16: 16px;
$px_18: 18px;
$px_20: 20px;
$px_24: 24px;
$px_26: 26px;
$px_28: 28px;
$px_30: 30px;
$px_32: 32px;
$px_34: 34px;
$px_36: 36px;
$px_40: 40px;
$px_44: 44px;
$px_46: 46px;
$px_48: 48px;
$px_50: 50px;
$px_54: 54px;
$px_56: 56px;
$px_60: 60px;
$px_72: 72px;
$px_80: 80px;
$px_82: 82px;
$px_84: 84px;
$px_96: 96px;
$px_120: 120px;
$px_140: 140px;
$px_144: 144px;
$px_200: 200px;

// special spacings
$footerSpacer: $px_56;
$formFieldMargin: $px_20;
$sectionGapMedium: $px_48;
$sectionGapLarge: $px_96;
$autocompletePanelSideSpacing: $px_18;
