@import 'styles/foundation';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .carousel {
    padding: $px_48 $px_20;
    padding-bottom: 0;
    max-width: 100vw;
    display: block;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .grid {
    display: none;
    @include media-breakpoint-down(lg) {
      padding: 0 $px_24;
      margin-top: $px_48;
      margin-bottom: $px_24;
      display: flex;
      justify-content: center;
    }
  }

  .button {
    border-radius: 1000px;
    padding: $px_16 $px_24;
    background-color: $systemColourBlack;

    span {
      color: var(--secondary-yellow-1, #fffbf0);
      text-align: center;
      font-size: $px_16;
      font-style: normal;
      font-weight: $mediumWeight;
      line-height: 123%; /* 19.68px */
      letter-spacing: -0.5px;
    }
  }
}
