@import 'styles/foundation';

.container {
  display: block;

  .header--default {
    text-align: center;
    color: $grey700;
    white-space: nowrap;
    padding: $px_4 $px_8 $px_12 $px_8;
    cursor: pointer;
    @include body-l-medium;

    &.active {
      color: $primaryBlue;
      border-bottom: 2px solid $primaryBlue;
    }

    .text {
      display: inline-block;
      margin-left: $px_4;
      margin-left: $px_0;
    }
  }

  .header--text {
    font-size: 42px;
    font-weight: $mediumWeight;
    line-height: 98%; /* 41.16px */
    letter-spacing: -2px;
    text-wrap: none;
    white-space: nowrap;

    @include media-breakpoint-down(lg) {
      font-size: 30px;
      line-height: 100%; /* 30px */
    }

    @include media-breakpoint-down(md) {
      font-size: $px_24;
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
      color: $systemColourBlack;
    }

    &.inactive {
      color: $systemColourGrey2;

      &:hover {
        color: $systemColourGrey1;
      }
    }
  }

  .single {
    &:hover {
      cursor: default;
    }
  }

  .header--rounded {
    white-space: nowrap;
  }
}
