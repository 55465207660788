$black: #000;
$darkGrey: #999;
$grey: #ccc;
$grey500: #808087;
$boxGrey: #f3f3f4;
$tableGrey: #f9f9f9;
$borderGrey: #ccccd1;
$pastelGray: #d1cfc6;
$tableGrey: #f9f9f9;
$grey800: #1a1a24;
$white: #fff;
$whiteSmoke: #f6f6f6;
$green: #64b03b;
$orange: #ffebd5;
$blue: #3e859d;
$columbiaBlue: #cee1e8;
$steelBlue: #253754;
$genieBlue: #6d7789;
$red: #ed5565;
$primaryOrange: #f8ac59;
$hoverOrange: #ffa23c;
$onClickOrange: #f1840e;
$primaryBlue: #253754;
$onClickBlue: #005084;
$darkImperialBlue: #253754;
$link: #337ab7;
$primaryGreen: #72c02c;
$hoverGreen: #6bb429;
$onClickGreen: #4a920a;
$primaryRed: #fe7475;
$secondaryRed: #de5962;
$hoverRed: #ed0404;
$venetianRed: #c50404;
$onClickRed: #cd0000;
$lightNavy: #2f4050;
$darkNavy: #2b3b4a;
$link: #1c7ec6;
$dialogBackgroundColor: #fafafd;
$beigeCtaColor: #f1eee6;

// Transparent colors
$transparentGrey: #cccccc80;
$beigePageBackground: #f6f3f1;

// States
$success: $green;
$info: $blue;
$fail: $red;
$disabled: $boxGrey;
$invalid: #ed55651a;

// Text
$text: #33333a;
$primaryText: #333;
$darkText: $darkGrey;
$lightGrayText: #86888a;
$whiteText: $white;
$liteText: $white;
$floralWhite: #fffbf0;
$tertiaryText: #b3b3b8;
$disabledText: $disabled;
$placeholderText: #9999a0;
$secondaryText: #33333a;
$link: $blue;
$brand: $green;
$border: $grey;
$toastInfo: #cde2f1;
$toastSuccess: #def1cc;
$toastWarning: #fff1f1;
$toastAlert: #fff1f1;
$toastAlertBorder: #ffd5d6;

// NEW COLORS:
$blue100: #f1f8fc;
$culturedGrey: #f8f8f8;
$primaryBlue50: #eff3f6;
$primaryBlue100: #eff3f6;
$primaryBlue300: #8ea8bf;
$primaryBlue500: #5d82a4;
$primaryBlue700: #4a6883;
$primaryBlue800: #253442;
$markatoBrown: #c39d66;
$logoLightGrey: #e5e5e4;
$grey50: #fafafd;
$grey100: #efeff2;
$grey200: #ccccd1;
$grey300: #b3b3b8;
$grey400: #9999a0;
$grey600: #4d4d54;
$grey700: #33333a;
$success50: #ecf2ed;
$success500: #5bb56a;
$success700: #407f4a;
$red100: #fff1f1;
$red500: #fe7475;
$red700: #de5962;
$warning100: #fff9ec;
$warning500: #ffbe3d;
$warning700: #b3852b;
$error100: #fff1f1;
$green300: #cee9d2;
$green500: #5bb56a;
$orange500: #ffbe3d;
$beige50: #f3ebe0;
$beige100: #e1ceb3;
$beige200: #cfb185;
$beige300: #c39d66;
$beige400: #9c7e52;
$hoverBlue: $primaryBlue700;

// Joey New Design
$primaryBlue1: #77a2b3;
$primaryBlue2: #4a6883;
$primaryBlue3: #384e62;
$secondaryYellow1: #fffbf0;
$secondaryYellow2: #f3f0e6;
$secondaryYellow3: #cc9c5e;
$secondaryOrange: #ffecd2;
$secondaryOrange2: #f2d7b2;
$systemColourBlack: #202020;
$systemColourGrey1: #d2cfc6;
$systemColourGrey2: #a7a8a9;
$gradientYellow: var(--gradient-yellow, linear-gradient(180deg, rgb(255 251 240 / 0%) -56.82%, $secondaryYellow1 100%));
$gradientBlack: var(--gradient-black, linear-gradient(0deg, #000 -16.79%, rgb(0 0 0 / 0%) 100%));
$primaryTransparentBlack: var(--primary-transparent-black, rgb(32 32 32 / 10%));
$silverLight: #d9d9d9;
$whiteChocolate: #ede9dd;
