@import 'styles/foundation';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .navigation {
    display: flex;
    flex-direction: row;
    width: 100vw;
    padding-left: 32px;
    padding-right: 32px;

    .cards {
      display: flex;
      flex-direction: row;
      width: 100%;

      &.cards--center {
        justify-content: center;
        gap: $px_32;
        @include media-breakpoint-down(lg) {
          overflow-x: scroll;
          width: calc(100vw);

          &::-webkit-scrollbar {
            display: none;
          }
        }

        @include media-breakpoint-down(sm) {
          justify-content: start;
        }
      }

      &.cards--default {
        border-bottom: 1px solid $grey100;
      }

      li {
        scroll-snap-align: start;
      }
    }
  }

  .content {
    padding: $px_18 $px_32;

    .controls {
      display: block;
      padding: $px_8 $px_16;
      text-align: center;
      background: $white;

      &:hover {
        display: block;
        background-color: $boxGrey;
        color: $black;
      }
    }

    .hidden {
      display: none;
    }
  }

  .variantContent {
    padding: 0 !important;
    width: fit-content;
    margin: 0 auto;
  }
}
