@import 'styles/foundation';

.container {
  display: flex;
  flex-direction: column;
  gap: $px_8;

  .imageWrapper {
    position: relative;
    display: flex;

    .image {
      border-radius: $px_20;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      &.big,
      &.small.tilegroup {
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1;
      }

      &.long {
        width: 330px;
        height: 660px;
      }

      &.keypointTile {
        @include media-breakpoint-down(xl) {
          width: calc(100vw - 160px);
          height: calc(100vw - 160px);
        }

        @include media-breakpoint-down(lg) {
          width: calc(100vw - 32px);
          height: calc(100vw - 32px);
        }
      }
    }

    .imageTitle {
      position: absolute;

      &.imageTitle--top-left {
        top: $px_24;
        left: $px_24;
        z-index: 1;
      }

      &.imageTitle--center {
        @include heading1-bold;

        color: $secondaryYellow1;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
      }
    }

    .imageLink {
      position: relative;
      width: 100%;

      .imageTitle {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0%;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        color: $secondaryYellow1;
        padding: 0 112.5px;
        font-size: 88px;
        font-weight: 500;
        line-height: 91.5%; /* 80.52px */
        letter-spacing: -3.5px;

        @include media-breakpoint-down(lg) {
          font-size: 42px;
          line-height: 98%; /* 41.16px */
          letter-spacing: -2px;
          padding: 0 55px;
        }
      }
    }

    .noPointer {
      cursor: default;
    }
  }

  .name {
    font-size: $px_20;
    font-weight: $mediumWeight;
    line-height: 98%; /* 21.56px */
    letter-spacing: -0.8px;
  }

  .link {
    padding: 0;
    font-size: $px_16;
    font-weight: $mediumWeight;
    color: $black;
    line-height: 123%; /* 19.68px */
    letter-spacing: -0.5px;
  }
}
