@import 'styles/foundation';

.container {
  display: flex;
  height: 402px;
  max-width: 100%;
  margin: $px_0 $px_20;
  gap: $px_24;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;

  > div {
    scroll-snap-align: start;
  }

  > div:nth-child(even) {
    padding-top: $px_48;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
