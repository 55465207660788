@import 'styles/foundation';

.container {
  max-width: $containerWidth;
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  padding: $px_0 $px_80;

  @include media-breakpoint-down(lg) {
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: $px_16;
    padding: $px_0 $px_24;
  }

  & > div {
    flex: 50%;
    min-width: 50%;
  }

  & > div:first-child {
    padding-right: $px_16;

    @include media-breakpoint-down(lg) {
      padding: 0;
    }
  }
}

.isReverse {
  grid-template-columns: 1fr 1.2fr;

  @include media-breakpoint-down(lg) {
    flex-direction: column-reverse;
  }
}
