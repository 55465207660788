// Font Families
$fontFamily: 'Poppins', sans-serif;
$fontFamilyHero: 'Poppins', sans-serif;

// Line heights
$superSmallLineHeight: 18px;
$smallLineHeight: 20px;
$regularLineHeight: 22px;
$semiLargeLineHeight: 30px;
$largeLineHeight: 48px;

// Font sizes
$tinyFontSize: 10px;
$smallFontSize: 12px;
$regularFontSize: 13px;
$mediumFontSize: 14px;
$semiLargeFontSize: 16px;
$almostLargeFontSize: 20px;
$largeFontSize: 24px;
$superLargeFontSize: 32px;
$extraLargeFontSize: 40px;

// Font Weights
$thinWeight: 300;
$regularWeight: 400;
$mediumWeight: 500;
$semiBoldWeight: 600;
$boldWeight: 700;
