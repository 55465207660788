@import '../utils';

@mixin regular-text {
  font-weight: $regularWeight;
  font-size: $regularFontSize;
  line-height: $smallLineHeight;
}

@mixin body-xxs {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.37px;
}

@mixin body-xxs-medium {
  @include body-xxs;

  font-weight: $mediumWeight;
}
@mixin body-sss-regular {
  font-weight: $regularWeight;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.07px;
}

@mixin body-s {
  font-size: 13px;
  line-height: 20px;
}
@mixin body-s-regular {
  @include body-s;

  font-weight: $regularWeight;
}
@mixin body-s-semi {
  @include body-s;

  font-weight: $semiBoldWeight;
}
@mixin body-m-medium {
  @include body-s;

  font-weight: $mediumWeight;
}
@mixin body-m-regular {
  @include body-s;

  font-weight: $regularWeight;
}

@mixin body-s-regular {
  @include body-s;

  font-weight: $regularWeight;
}

@mixin body-s-medium {
  @include body-s;

  font-weight: $mediumWeight;
}

@mixin body-s-bold {
  @include body-s-text;

  font-weight: $boldWeight;
}

@mixin body-m {
  font-size: 14px;
  line-height: $regularLineHeight;
  letter-spacing: -0.15px;
}

@mixin body-m-regular {
  @include body-m;

  font-weight: $regularWeight;
}

@mixin body-m-medium {
  @include body-m;

  font-weight: $mediumWeight;
}

@mixin body-m-semi {
  @include body-m;

  font-weight: $semiBoldWeight;
}

@mixin body-m-bold {
  @include body-m;

  font-weight: $boldWeight;
}

@mixin body-l-medium {
  @include body-m;

  font-weight: $mediumWeight;
}

@mixin body-l-semi {
  @include body-m;

  font-weight: $semiBoldWeight;
}

@mixin body-l-bold {
  @include body-m;

  font-weight: $boldWeight;
}
@mixin body-l-text {
  font-weight: $semiBoldWeight;
  font-size: 15px;
  line-height: $regularLineHeight;
  letter-spacing: -0.24px;
}
@mixin body-l-regular {
  font-weight: $regularWeight;
  font-size: 15px;
  line-height: $regularLineHeight;
  letter-spacing: -0.24px;
}
@mixin body-m-bold {
  @include body-m;

  font-weight: $semiBoldWeight;
}

@mixin body-xs {
  font-size: 12px;
  line-height: $superSmallLineHeight;
}

@mixin body-xs-regular {
  @include body-xs;

  font-weight: $regularWeight;
}

@mixin body-xs-semi {
  @include body-xs;

  font-weight: $mediumWeight;
}

@mixin body-xs-medium {
  @include body-xs;

  font-weight: $semiBoldWeight;
}

@mixin body-xs-bold {
  @include body-xs;

  font-weight: $boldWeight;
}

@mixin body-s-text {
  font-weight: $regularWeight;
  font-size: 13px;
  line-height: $smallLineHeight;
}

@mixin body-s-regular {
  font-weight: $regularWeight;
  font-size: 13px;
  line-height: $regularLineHeight;
}

@mixin body-s-bold {
  font-weight: $boldWeight;
  font-size: 13px;
  line-height: $regularLineHeight;
  letter-spacing: -0.15px;
}

@mixin body-s-semi {
  font-weight: $semiBoldWeight;
  font-size: 13px;
  line-height: $regularLineHeight;
  letter-spacing: -0.15px;
}

@mixin heading7 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

@mixin heading7-regular {
  @include heading7;

  font-weight: $regularWeight;
}
@mixin heading7-semi-bold {
  @include heading7;

  font-weight: $semiBoldWeight;
}
@mixin heading7-bold {
  @include heading7;

  font-weight: $boldWeight;
}

@mixin heading6 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.45px;
}

@mixin heading6-regular {
  @include heading6;

  font-weight: $regularWeight;
}
@mixin heading6-medium {
  @include heading6;

  font-weight: $mediumWeight;
}
@mixin heading6-semi {
  @include heading6;

  font-weight: $semiBoldWeight;
}
@mixin heading6-bold {
  @include heading6;

  font-weight: $boldWeight;
}

@mixin heading5 {
  font-size: $almostLargeFontSize;
  line-height: $semiLargeLineHeight;
  letter-spacing: 0.38px;
}
@mixin heading5-regular {
  @include heading5;

  font-weight: $regularWeight;
}
@mixin heading5-semi {
  @include heading5;

  font-weight: $semiBoldWeight;
}
@mixin heading5-bold {
  @include heading5;

  font-weight: $boldWeight;
}

@mixin heading3 {
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.37px;
}

@mixin heading3-regular {
  @include heading3;

  font-weight: $regularWeight;
}
@mixin heading3-medium {
  @include heading3;

  font-weight: $mediumWeight;
}
@mixin heading3-bold {
  @include heading3;

  font-weight: $boldWeight;
}

@mixin headline2 {
  font-size: 28px;
  line-height: $largeLineHeight;
  letter-spacing: 0.37px;
}

@mixin headline2-regular {
  @include headline2;

  font-weight: $regularWeight;
}

@mixin headline2-medium {
  @include headline2;

  font-weight: $mediumWeight;
}

@mixin headline2-semi {
  @include headline2;

  font-weight: $semiBoldWeight;
}

@mixin headline2-bold {
  @include headline2;

  font-weight: $boldWeight;
}

@mixin headline5-bold {
  font-weight: $boldWeight;
  font-size: $almostLargeFontSize;
  line-height: $semiLargeLineHeight;
  letter-spacing: -0.38px;
}

@mixin headline7-regular {
  font-weight: $regularWeight;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

@mixin headline7-semi {
  font-weight: $semiBoldWeight;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.36px;
}
@mixin headline7-bold {
  font-weight: $boldWeight;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

@mixin headline8-medium {
  font-weight: $mediumWeight;
  font-size: $extraLargeFontSize;
  line-height: 120%;
  letter-spacing: 0.37px;
}

@mixin heading4 {
  font-size: $largeFontSize;
  line-height: 36px;
  letter-spacing: 0.36px;
}
@mixin heading4-regular {
  @include heading4;

  font-weight: $regularWeight;
}
@mixin heading4-medium {
  @include heading4;

  font-weight: $mediumWeight;
}
@mixin heading4-bold {
  @include heading4;

  font-weight: $boldWeight;
}

@mixin heading3 {
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.37px;
}
@mixin heading3-regular {
  @include heading3;

  font-weight: $regularWeight;
}
@mixin heading3-medium {
  @include heading3;

  font-weight: $mediumWeight;
}
@mixin heading3-bold {
  @include heading3;

  font-weight: $boldWeight;
}

@mixin heading2 {
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.36px;
}

@mixin heading2-regular {
  @include heading2;

  font-weight: $regularWeight;
}
@mixin heading2-medium {
  @include heading2;

  font-weight: $mediumWeight;
}
@mixin heading2-bold {
  @include heading2;

  font-weight: $boldWeight;
}

@mixin heading1 {
  font-family: Poppins;
  font-style: normal;
  font-size: 48px;
  line-height: $largeLineHeight;
  letter-spacing: 0.37px;
}
@mixin heading1-regular {
  @include heading1;

  font-weight: $regularWeight;
}
@mixin heading1-medium {
  @include heading1;

  font-weight: $mediumWeight;
}
@mixin heading1-bold {
  @include heading1;

  font-weight: $boldWeight;
}

@mixin lp-heading-bold {
  font-family: $fontFamilyHero;
  font-size: $extraLargeFontSize;
  line-height: $largeLineHeight;
  font-weight: $boldWeight;
}
