@import 'styles/foundation';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $px_16;
  justify-content: flex-end;

  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
}

.isReverse {
  justify-content: start;
}
